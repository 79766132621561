// TODO: Create alias for less verbosity

import DesignSystem from 'design-system-utils'
import palette from '@tokens/palette'
import { easing, duration } from '@tokens/easing'
import { generateMedia, pxToRem } from 'styled-media-query'
import { spacingValues } from '@tokens/spacing'
export { box, spacing } from '@tokens/spacing'

const fontFamily = {
  arboria: '"arboria", "Helvetica", "Arial", "sans-serif"',
  arboriaFallback: '"Helvetica", "Arial", "sans-serif"',
  itcBold: '"ITC Blair W01 Condensed Bold", "Roboto", "Helvetica Neue", "Franklin Gothic Medium", "sans-serif"',
  itcMedium: '"ITC Blair W01 Condensed Medium", "Roboto", "Helvetica Neue", "Franklin Gothic Medium", "sans-serif"',
  itcFallback: '"Roboto", "Helvetica Neue", "Franklin Gothic Medium", "sans-serif"',
}

export const tokens = new DesignSystem(
  {
    type: {
      baseFontSize: '16px',

      fontFamily,
      fontFamilyBase: fontFamily.arboria,
      fontFamilyHeading: fontFamily.itcBold,

      lineHeight: {
        body: 1.5,
        homeHeading: 1.05,
        homeSubHeading: 1.25,
        h1: 1.0357,
        h2: 1.166667,
        h3: 1.375,
        h4: 1.3333,
        h5: 1.3333,
        h6: 1.3333,
      },

      fontWeight: {
        normal: 400,
        bold: 600,
      },

      letterSpacing: {
        base: 'normal',
        small: '0.0375em',
        medium: '0.0625em',
        large: '0.125em',
      },
    },

    // TODO: Rethink this shit maybe
    spacing: {
      ...spacingValues,
    },

    colors: {
      ...palette,
    },

    zIndex: {
      under: -1,
      base: 0,
      content: 1,
      aboveContent: 2,
      overlay: 20,
      flyout: 30,
      header: 35,
      nav: 40,
      search: 50,
      cover: 100,
    },

    maxWidth: {
      tiny: '30rem', // 480
      slim: '32.5rem', // 520
      narrow: '40rem', // 640
      mid: '47.6rem', // 760
      base: '60rem', // 960
      wide: '72rem', // 1152
      vastSmall: '88rem', // 1408
      vast: '96.25rem', // 1540
    },

    easing,
    duration,

    transition: {
      base: `${easing.base} ${duration.base}`,
      snappy: `${easing.out} ${duration.fast}`,
      fancy: `${easing.outCubic} ${duration.slow}`,
    },

    borderRadius: '0.2rem',

    component: {
      header: {
        height: {
          s: '7rem',
          m: '9rem',
          l: '9rem',
        },
      },
      hero: {
        offset: {
          s: '5rem',
          m: '6rem',
          l: '4rem',
        }
      }
    },

    speedLinesSettings: {
      min: 40,
      max: 250,
    }
  },
  {
    fontSizeUnit: 'rem',
  }
)

export const media = generateMedia(
  pxToRem({
    s: '600px',
    m: '900px',
    l: '1200px',
    xl: '1800px',
  })
)

export default tokens
