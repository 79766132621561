import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { Normalize } from 'styled-normalize'
import GlobalStyle from '@styles/components/Global'

const enableOutline = event => {
  /* tab */
  if (event.which === 9) {
    document.body.classList.add('focus-outline')
  }
}

const Layout = ({ children }) => {
  useEffect(() => {
    // Listen to tab events to enable outlines (accessibility improvement)
    document.body.addEventListener('keyup', enableOutline)
    return () => {
      document.body.removeEventListener('keyup', enableOutline)
    }
  }, []) // Will only fire on mount due to the empty

  return (
    <div className="sack">
      {/* TODO: update Helmet data via source file or source data */}
      <Helmet title="Home | Overdrive" />
      <Normalize />
      <GlobalStyle />
      {children}
    </div>
  )
}

export default Layout
