const colors = {
  white: {
    base: '#fff',
    dark: '#f9f9fa',
  },
  black: {
    base: '#000',
  },
  red: {
    base: '#ff8383',
    light: '#ffa6a6',
  },
  teal: {
    base: '#46dcd0',
    light: '#70f0e6',
  },
  blue: {
    base: '#46dcd0',
    lighter: '#70f0e6',
  },
  yellow: {
    base: '#fff184',
  },
  pink: {
    base: '#ff8383',
    lighter: '#ffa6a6',
  },
  grey: {
    base: '#4a4a4a',
    lightish: '#808080',
    light: '#9c9c9c',
    lighter: '#e6e6e6',
    dark: '#303030',
    darker: '#181d25',
  },
}

const brand = {
  light: '#f8f2e2',
  lightAlt: '#fffcf3',
  lightDarken: '#e4dbc5',
  dark: '#1a1b1b',
  darkAlt: '#383b3b',
  // We need to rename this stuff
  darkAlt2: '#6b7378',
}

export default {
  colorPalette: {
    ...colors,
    text: {
      base: brand.dark,
    },
    border: {
      base: brand.lightDarken,
    },
    disabled: {
      base: colors.grey.base,
    },
    link: {
      base: brand.dark,
      hover: brand.darkAlt,
      focus: brand.darkAlt,
      active: brand.darkAlt,
    },
    success: {
      base: colors.teal.base,
    },
    error: {
      base: colors.red.base,
    }
  },
  brand: {
    ...brand,
  },
}
