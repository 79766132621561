import React, { Component } from 'react'
import { FlyoutContext } from '@context/flyout-context'

// TODO finish to pass references to content/components?
class FlyoutLink extends Component {
  toggleFlyout = (e) => {
    e.preventDefault()
    this.context.postType = this.props.postType
    this.context.sid = this.props.sid
    this.context.toggleFlyout()
  }

  render() {
    return (
      <FlyoutContext.Consumer>
        {() =>
          <a
            href="#0"
            className={this.props.className}
            onClick={this.toggleFlyout}
          >
            {this.props.children}
          </a>
        }
      </FlyoutContext.Consumer>
    )
  }
}

FlyoutLink.contextType = FlyoutContext

export default FlyoutLink
