import styled, { css } from 'styled-components'
import { tokens, spacing } from '@tokens'
import { easing, duration } from '@tokens/easing'
import CleverLink from '@partials/CleverLink'
import { ITCBold } from '@styles/Typography'

export const ButtonStyles = css`
  ${ITCBold};
  position: relative;
  cursor: pointer;
  display: inline-flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin-right: ${spacing()};
  margin-bottom: ${spacing()};
  padding: ${spacing(2)} ${spacing(4)};
  background-color: ${tokens.color('link')};
  box-shadow: inset 0 0 0 ${spacing(0.5)} ${tokens.color('link')};
  border: none;
  border-radius: ${spacing(0.5)};
  color: ${tokens.brand('light')};
  letter-spacing: ${tokens.get('type.letterSpacing.medium')};
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.125;
  text-decoration: none;
  transition: all ${tokens.get('transition.snappy')};

  &::before {
    position: absolute;
    top: ${spacing(-0.5)};
    left: ${spacing(-0.5)};
    right: ${spacing(-0.5)};
    bottom: ${spacing(-0.5)};
    display: block;
    content: '';
    border-radius: ${spacing(0.5)};
    background: transparent;
    transition: transform 250ms ${easing.outBack}, opacity 250ms linear;
    transform: scale(1.05);
    opacity: 0;
    box-shadow: 0 0 0 ${spacing(0.5)} ${tokens.brand('dark')};
    pointer-events: none;
  }

  .background-color-dark & {
    background-color: ${tokens.brand('light')};
    box-shadow: inset 0 0 0 ${spacing(0.5)} ${tokens.brand('light')};
    color: ${tokens.brand('dark')};

    &::before {
      box-shadow: 0 0 0 ${spacing(0.5)} ${tokens.brand('light')}
    }
  }

  &:hover,
  &:focus,
  &:active {
    outline: none;

    &::before {
      transform: scale(1);
      opacity: 1;
    }
  }

  &:last-child {
    margin-right: 0;
  }
`

export const Button = styled(CleverLink)`
  ${ButtonStyles};
`
