import { css } from 'styled-components'
import { tokens, spacing, media } from '@tokens'
import { Arboria } from '@styles/Typography'

export const snipCartCSS = css`
  #snipcart {
     /* -----------------
        Colors
    ----------------- */
    --color-default: ${tokens.brand('light')};
    --color-alt: ${tokens.brand('lightAlt')};
    --color-icon: ${tokens.brand('light')};
    --color-success: ${tokens.color('success')};
    --color-error: ${tokens.color('error')};

    --color-link: ${tokens.brand('light')};
    --color-link-hover: ${tokens.brand('light')};
    --color-link-active: var(--color-link);
    --color-link-focus: var(--color-link);

    --color-input: var(--color-default);
    --color-input-hover: var(--color-input);
    --color-input-focus: var(--color-input);
    --color-input-checked: var(--color-input);
    --color-input-disabled: var(--color-alt);
    --color-input-error: var(--color-error);
    --color-inputLabel: var(--color-default);
    --color-inputLabel-hover: var(--color-inputLabel);
    --color-inputLabel-focus: var(--color-inputLabel);
    --color-inputIcon: var(--color-alt);
    --color-inputIcon-hover: var(--color-default);
    --color-inputIcon-focus: var(--color-inputIcon);
    --color-inputIcon-checked: var(--color-default);
    --color-inputIcon-error: var(--color-error);
    --color-inputPlaceholder: var(--color-alt);

    --color-buttonPrimary: ${tokens.brand('dark')};
    --color-buttonPrimary-hover: ${tokens.brand('dark')};
    --color-buttonPrimary-active: var(--color-buttonPrimary);
    --color-buttonPrimary-focus: var(--color-buttonPrimary);
    --color-buttonPrimary-disabled: var(--color-alt);
    --color-buttonPrimary-success: var(--color-buttonPrimary);
    --color-buttonPrimary-error: var(--color-buttonPrimary);

    --color-buttonSecondary: ${tokens.brand('light')};
    --color-buttonSecondary-hover: ${tokens.brand('light')};
    --color-buttonSecondary-active: var(--color-buttonSecondary);
    --color-buttonSecondary-focus: var(--color-buttonSecondary);
    --color-buttonSecondary-disabled: hsl(210, 10%, 25%);
    --color-buttonSecondary-success: var(--color-success);
    --color-buttonSecondary-error: var(--color-error);

    --color-buttonDanger: var(--color-error);
    --color-buttonDanger-hover: hsl(6, 55%, 70%);
    --color-buttonDanger-active: var(--color-buttonDanger);
    --color-buttonDanger-focus: var(--color-buttonDanger);
    --color-buttonDanger-disabled: hsl(210, 10%, 25%);
    --color-buttonDanger-success: var(--color-default);
    --color-buttonDanger-error: var(--color-default);

    --color-badge: ${tokens.brand('light')};
    --color-badge-active: ${tokens.brand('dark')};
    --color-badge-disabled: ${tokens.brand('light')};

    /* -----------------
        Border colors
    ----------------- */
    --borderColor-default: ${tokens.color('border')};
    --borderColor-error: ${tokens.color('error')};

    --borderColor-link: ${tokens.brand('light')};
    --borderColor-link-hover: ${tokens.brand('light')};
    --borderColor-link-active: ${tokens.brand('light')};
    --borderColor-link-focus: ${tokens.brand('light')};

    --borderColor-input: ${tokens.color('grey')};
    --borderColor-input-hover: ${tokens.color('grey')};
    --borderColor-input-focus: var(--borderColor-input-hover);
    --borderColor-input-checked: var(--borderColor-input-hover);
    --borderColor-input-disabled: hsl(210, 10%, 20%);
    --borderColor-input-error: var(--borderColor-error);

    --borderColor-buttonPrimary: transparent;
    --borderColor-buttonPrimary-hover: transparent;
    --borderColor-buttonPrimary-focus: transparent;
    --borderColor-buttonPrimary-disabled: transparent;
    --borderColor-buttonPrimary-success: transparent;
    --borderColor-buttonPrimary-error: transparent;

    --borderColor-buttonSecondary: transparent;
    --borderColor-buttonSecondary-hover: transparent;
    --borderColor-buttonSecondary-focus: transparent;
    --borderColor-buttonSecondary-disabled: transparent;
    --borderColor-buttonSecondary-success: transparent;
    --borderColor-buttonSecondary-error: transparent;

    --borderColor-badge: transparent;
    --borderColor-badge-active: transparent;
    --borderColor-badge-disabled: transparent;

    /* -----------------
        Background colors
    ----------------- */
    --bgColor-default: ${tokens.brand('dark')};
    --bgColor-alt: ${tokens.brand('dark')};
    --bgColor-success: hsl(144, 70%, 15%);
    --bgColor-error: hsl(6, 50%, 15%);
    --bgColor-info: hsl(210, 55%, 15%);

    --bgColor-modal: ${tokens.brand('darkAlt')};
    --bgColor-modalVeil: hsla(210, 10%, 7%, .75);

    --bgColor-link: none;
    --bgColor-link-hover: none;
    --bgColor-link-active: none;
    --bgColor-link-focus: hsl(210, 55%, 10%);

    --bgColor-input: hsl(210, 10%, 10%);
    --bgColor-input-hover: var(--bgColor-input);
    --bgColor-input-focus: var(--bgColor-input);
    --bgColor-input-checked: var(--borderColor-input-hover);
    --bgColor-input-disabled: hsl(210, 10%, 14%);
    --bgColor-input-error: var(--bgColor-input);
    --bgColor-input-autofill: hsl(210, 60%, 15%);

    --bgColor-buttonPrimary: ${tokens.brand('light')};
    --bgColor-buttonPrimary-hover: ${tokens.brand('lightDarken')};
    --bgColor-buttonPrimary-active: var(--bgColor-buttonPrimary);
    --bgColor-buttonPrimary-focus: var(--bgColor-buttonPrimary);
    ${'' /* --bgColor-buttonPrimary-disabled: ${tokens.color('disabled')}; */}
    ${'' /* --bgColor-buttonPrimary-success: ${tokens.color('success')};
    --bgColor-buttonPrimary-error: ${tokens.color('error')}; */}

    --bgColor-buttonSecondary: ${tokens.brand('darkAlt')};
    --bgColor-buttonSecondary-hover: hsl(210, 60%, 18%);
    --bgColor-buttonSecondary-active: var(--bgColor-buttonSecondary);
    --bgColor-buttonSecondary-focus: var(--bgColor-buttonSecondary);
    --bgColor-buttonSecondary-disabled: hsl(210, 10%, 9%);
    --bgColor-buttonSecondary-success: var(--bgColor-success);
    --bgColor-buttonSecondary-error: var(--bgColor-error);

    --bgColor-buttonDanger: var(--bgColor-error);
    --bgColor-buttonDanger-hover: hsl(6, 50%, 18%);
    --bgColor-buttonDanger-active: var(--bgColor-buttonDanger);
    --bgColor-buttonDanger-focus: var(--bgColor-buttonDanger);
    --bgColor-buttonDanger-disabled: hsl(210, 10%, 9%);
    --bgColor-buttonDanger-success: hsl(144, 66%, 30%);
    --bgColor-buttonDanger-error: hsl(6, 60%, 35%);

    --bgColor-badge: ${tokens.color('grey')};
    --bgColor-badge-active: ${tokens.brand('light')};
    --bgColor-badge-disabled: ${tokens.color('grey')};

    /* -----------------
        Shadows
    ----------------- */

    --shadow-default: 0px 20px 24px -20px hsla(0, 0%, 0%, .5);
    --shadow-tooltip: transparent;

    --shadow-link-focus: transparent;

    --shadow-input-hover: none;
    --shadow-input-focus: transparent;
    --shadow-input-checked: none;

    --shadow-buttonPrimary-hover: transparent;
    --shadow-buttonPrimary-active: transparent;
    --shadow-buttonPrimary-focus: transparent;

    --shadow-buttonSecondary-hover: transparent;
    --shadow-buttonSecondary-active: transparent;
    --shadow-buttonSecondary-focus: transparent;

    --shadow-buttonDanger-hover: transparent;
    --shadow-buttonDanger-active: transparent;
    --shadow-buttonDanger-focus: transparent;

    /* -----------------
        Colors
    ----------------- */

    input[type='button'],
    input[type='submit'],
    button {
      box-shadow: none;

      &::before {
        display: none;
      }
    }

    .snipcart-modal__container {
      z-index: 9999
    }

    .snipcart-item-line__content-container {
      margin-left: 0;
      margin-right: auto;
      padding-left: ${spacing(1)};
      padding-right: ${spacing(1)};

      .snipcart-item-line__title {
        padding: 0;
      }

      .snipcart-item-description {
        margin-top: ${spacing(0.5)};
      }
    }

    .snipcart-cart__secondary-header {
      padding: 0 ${spacing(1)} 0 0;
      min-height: ${spacing(4)};
      justify-content: flex-end;
      flex: 0;
      margin-top: ${spacing(2)};

      .snipcart__font--secondary {
        display: none;
      }
    }

    .snipcart-item-quantity__quantity {
      height: ${spacing(4)};
      border: none;
      padding: 0;
      max-width: ${spacing(12)};
    }

    .snipcart-item-quantity__label {
      display: none;
    }

    .snipcart__font--secondary,
    .snipcart__font--subtitle,
    .snipcart__font--subtitle-small,
    .snipcart-base-button__label {
      ${Arboria}
    }

    .snipcart-item-line__media--small {
      position: relative;
      width: ${spacing(6)};
      height: ${spacing(6)};
      flex: 0 0 auto;
      align-self: flex-start;

      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        max-width: none;
        width: 100%;
        height: 100%;
      }
    }

    .snipcart-input,
    .snipcart-form__select {
      height: ${spacing(5)};
      min-height: ${spacing(5)};
    }

    .snipcart-summary-fees {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;

      > * {
        width: 100%;
      }
    }

    .snipcart-summary-fees__notice {
      text-align: right;
      margin-bottom: 0;
      margin-top: ${spacing(1)};
      order: 3;
    }

    .snipcart-flash-message__content {
      color: ${tokens.color('white')};
    }

    .snipcart-cart__footer-buttons {
      padding-bottom: ${spacing(2)};

      .snipcart-button-link {
        display: none;
      }
    }

    .snipcart-cart-summary-item {
      margin: 0;
    }

    .snipcart-form__cell--input-aligned {
      margin-bottom: 10px;
    }

    .snipcart-button-link {
      border-radius: 0;
    }

    .snipcart-input {
      color: var(--color-input,#2e3338) !important;
    }

    .snipcart-form__address-autocomplete,
    .snipcart-form__cell--tidy {
      .snipcart-form__label {
        font-size: 12px;
      }
    }
  }

  .snipcart-item-line__media {
    border-radius: 0.25rem;
    overflow: hidden;
  }

  .snipcart-error-message {
    svg path {
      fill: ${tokens.color('white')};
    }
  }

  .snipcart-flash-message__title,
  .snipcart-flash-message__content {
    text-transform: none;
  }
`

export default snipCartCSS
