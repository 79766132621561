import { createGlobalStyle } from 'styled-components'
import { BaseType, BaseTypeSizes, LargeTypeSizes, Heading, H1Sizes, H2Sizes, H3Sizes, H4Sizes, H5Sizes, H6Sizes } from '@styles/Typography'
import { ButtonStyles } from '@styles/components/Button'
import { tokens, spacing, media } from '@tokens'
import snipCartCSS from './snipCartCSS'

const Global = createGlobalStyle`
  ${snipCartCSS}
  /* RESET */
  body,
  h1, h2, h3, h4, h5, h6,
  blockquote, p, pre,
  dl, dd, ol, ul,
  figure,
  hr,
  fieldset, legend {
    margin:  0;
    padding: 0;
  }

  li > {
    ol, ul {
      margin-bottom: 0;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  fieldset {
    border: 0;
    min-width: 0;
  }

  /* GLOBAL */
  html {
    ${BaseType};
    box-sizing: border-box;
    background-color: ${tokens.brand('light')};
    color: ${tokens.color('text')};
    font-size: ${tokens.get('type.baseFontSize')};
    line-height: ${tokens.get('type.lineHeight.body')};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;

    * {
      outline-color:  ${tokens.color('grey')};
      outline-offset: ${spacing(0.5)};

      &, &::before, &::after {
        box-sizing: inherit;
      }
    }

    body:not(.focus-outline) {
      * {
        outline: none;
      }
    }
  }

  ::selection {
    background: ${tokens.color('yellow')};
  }

  body {
    ${BaseTypeSizes};
  }

  main,
  article {
    position: relative;
    z-index: ${tokens.z('content')};
  }

  address,
  h1, h2, h3, h4, h5, h6,
  p,
  blockquote, pre,
  dl, ol, ul,
  hr,
  table,
  fieldset {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  /* TODO: Make this implementation better. Componentized heading tags are good but don't play nice with wysiwyg */
  h1 {
    ${Heading};
    ${H1Sizes};
  }

  h2 {
    ${Heading};
    ${H2Sizes};
  }

  h3 {
    ${Heading};
    ${H3Sizes};
  }

  h4 {
    ${Heading};
    ${H4Sizes};
  }

  h5 {
    ${Heading};
    ${H5Sizes};
  }

  h6 {
    ${Heading};
    ${H6Sizes};
  }

  dl, ol, ul {
    margin-left: 1.5rem;
  }

  a {
    color: ${tokens.color('link')};
    display: inline-block;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .sack {
    background: ${tokens.brand('light')};
    position: relative;
    min-height: 100vh;
    width: 100%;
  }

  .tl-wrapper {
    > .container {
      background: ${tokens.brand('light')};
      min-height: 100vh;
      position: relative;
    }

    &-status {
      &--entered {
        transform: none !important;
      }
    }
  }

  ${''
    /*
    fake box-shadow on the header in order to
    insert the mobile nav between the shadow & the header
    */
  }
  .sticky-header .container::before {
    display: block;
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 0 ${spacing(3)} rgba(0, 0, 0, 0.075);
    z-index: ${tokens.z('overlay')};
    height: ${parseInt(tokens.get('component.header.height.s')) * 0.7 + 'rem'};

  ${media.greaterThan('s')`
    height: ${parseInt(tokens.get('component.header.height.m')) * 0.7 + 'rem'};
  `}

  ${media.greaterThan('m')`
    height: ${parseInt(tokens.get('component.header.height.l')) * 0.7 + 'rem'};
  `}
  }

  .transition-layer {
    position: fixed;
    top:  0;
    left: 0;
    height: 100vh;
    width: 100vw;
    transform: translate(100%, 0);
    z-index: ${tokens.z('cover')};
  }

  .sack {
    position: relative;
    width: 100%;

    ${'' /* // TODO Do this better */}
    .tl-edges {
      overflow: unset !important
    }
  }

  .tl-wrapper {
    box-shadow: 0 0.75rem 1.5rem 0 rgba(0, 0, 0, 0.05);

    &-status {
      &--entered {
        transform: none !important;
      }
    }

    body.is-locked & {
      height: 100vh;
      overflow: hidden;
    }
  }

  /* FORM */
  .forms__wrap {
    input[type='tel'],
    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='search'],
    textarea,
    select {
      ${LargeTypeSizes};
      appearance: none;
      background-color: transparent;
      border: 0;
      border-bottom: ${tokens.color('text')} 2px solid;
      color: ${tokens.color('text')};
      display: block;
      padding: 0 0 ${spacing(2)};
      font-size: 1rem;
      line-height: 1.5;
      transition: border-color ${tokens.get('transition.base')};
      resize: none;
      outline: 0;
      width: 100%;

      &:hover,
      &:focus {
        border-color: ${tokens.brand('darkAlt')};
      }

      &::placeholder {
        ${LargeTypeSizes};
        color: ${tokens.color('grey', 'light')};
      }

      &:only-child {
        margin-bottom: 0;
      }
    }

    textarea {
      border: ${tokens.brand('darkAlt2')} 2px solid;
      border-radius: 4px;
      padding: ${spacing(3)} ${spacing(4)};
      min-height: 14rem;
    }
  }

  input[type='button'],
  input[type='submit'],
  button {
    ${ButtonStyles};
  }

`

export default Global
