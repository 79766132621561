// TODO: Refactor (it's kinda messy)

import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import FlyoutLink from '@partials/FlyoutLink'

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
export const CleverLink = ({
  children,
  to = '#0',
  type,
  flyout,
  // file,
  activeClassName = 'is-active',
  partiallyActive,
  isVisible = true,
  transition,
  sid,
  useFlyout,
  postType,
  ...other
}) => {
  if (type === 'flyout' && flyout) {
    sid = flyout[0]
    postType = type
  }

  // Define whether link is internal based on either WP definition or URL
  // starting with a single slash
  const isFlyout = type === 'flyout'
  const isInternal = type === 'internal' || /^\/(?!\/)/.test(to)
  const isFile = type === 'download' || (isInternal && /\.[0-9a-z]+$/i.test(to))
  let props = {}

  // Render FlyoutLink component if link type is flyout

  // TODO: We should include the real path in there too for any that just leverage
  //       useFlyout for accessibility reasons. If we don't there will be no way to
  //       reach the page other than the flyout.
  if ((useFlyout || isFlyout) && sid && postType) {
    return (
      <FlyoutLink sid={sid} postType={postType} {...other}>
        {children}
      </FlyoutLink>
    )
  }

  if (isFile) {
    // TODO: Remove or uncomment if needed for accurate file names
    // props.download = file.name
  } else if (!isInternal && type !== 'flyout') {
    props.target = '_blank'
    props.rel = 'noopener noreferrer'
  }

  // Use Gatsby Link for internal links, and <a> for others
  if (isInternal) {
    if (isFile || to === '#0') {
      return (
        <a href={to} tabIndex={isVisible ? 0 : -1} {...other} {...props}>
          {children}
        </a>
      )
    }

    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        tabIndex={isVisible ? 0 : -1}
        {...other}
        {...props}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a href={to} tabIndex={isVisible ? 0 : -1} {...other} {...props}>
      {children}
    </a>
  )
}

// TODO: Add PropTypes

export default CleverLink
