import styled, { css } from 'styled-components'
import { tokens, media, spacing } from '@tokens'

export const Arboria = css`
  font-family: ${tokens.get('type.fontFamily.arboriaFallback')};
  font-weight: ${tokens.get('type.fontWeight.normal')};

  .wf-active &,
  &.wf-active {
    font-family: ${tokens.get('type.fontFamily.arboria')};
  }
`

export const ITCBold = css`
  font-family: ${tokens.get('type.fontFamily.itcFallback')};
  font-weight: ${tokens.get('type.fontWeight.bold')};

  .wf-active &,
  &.wf-active {
    font-family: ${tokens.get('type.fontFamily.itcBold')};
  }
`

export const ITCMedium = css`
  font-family: ${tokens.get('type.fontFamily.itcFallback')};

  .wf-active & {
    font-family: ${tokens.get('type.fontFamily.itcMedium')};
  }
`

/* TODO: Make this implementation better. Componentized heading tags are good but don't play nice with wysiwyg */
export const Heading = css`
  ${ITCBold};
  margin: 0 0 1.5rem;
  text-transform: uppercase;
`

export const H1Sizes = css`
  /* TODO: Maybe build a function to calculate line-height / sizes? */
  font-size: 2rem;
  line-height: ${tokens.get('type.lineHeight.h1')};

  ${media.greaterThan('s')`
    font-size: 2.5rem;
  `}

  ${media.greaterThan('m')`
    font-size: 3rem;
    margin: 0 0 2rem;
  `}

  ${media.greaterThan('l')`
    font-size: 3.5rem;
    margin: 0 0 2.5rem;
  `}
`

export const H2Sizes = css`
  font-size: 1.5rem;
  line-height: ${tokens.get('type.lineHeight.h2')};

  ${media.greaterThan('s')`
    font-size: 2rem;
  `}

  ${media.greaterThan('m')`
    font-size: 2.5rem;
    margin: 0 0 1.5rem;
  `}

  ${media.greaterThan('xl')`
    font-size: 3rem;
    margin: 0 0 1.5rem;
  `}
`

export const H3Sizes = css`
  font-size: 1.25rem;
  line-height: ${tokens.get('type.lineHeight.h3')};

  ${media.greaterThan('s')`
    font-size: 1.5rem;
  `}

  ${media.greaterThan('m')`
    font-size: 1.75rem;
  `}

  ${media.greaterThan('xl')`
    font-size: 2rem;
  `}
`

export const H4Sizes = css`
  font-size: 1.125rem;
  line-height: ${tokens.get('type.lineHeight.h4')};

  ${media.greaterThan('s')`
    font-size: 1.25rem;
  `}

  ${media.greaterThan('m')`
    font-size: 1.5rem;
  `}
`

export const H5Sizes = css`
  font-size: 0.875rem;
  line-height: ${tokens.get('type.lineHeight.h5')};

  ${media.greaterThan('s')`
    font-size: 1rem;
  `}

  ${media.greaterThan('m')`
    font-size: 1.125rem;
  `}
`
export const H6Sizes = css`
  font-size: 0.75rem;
  line-height: ${tokens.get('type.lineHeight.h6')};

  ${media.greaterThan('s')`
    font-size: 0.875rem;
  `}

  ${media.greaterThan('m')`
    font-size: 1rem;
  `}
`

export const BaseType = css`
  ${Arboria};
  letter-spacing: initial;
  text-transform: none;
`

export const BaseTypeSizes = css`
  font-size: 1rem;
  font-weight: ${tokens.get('type.fontWeight.normal')};
  line-height: ${tokens.get('type.lineHeight.body')};

  ${media.greaterThan('m')`
    font-size: 1.125rem;
  `}
`

export const MediumTypeSizes = css`
  font-size: 1.125rem;
  line-height: ${tokens.get('type.lineHeight.body')};

  ${media.greaterThan('m')`
    font-size: 1.25rem;
  `}
`

export const LargeTypeSizes = css`
  font-size: 1.125rem;
  line-height: ${tokens.get('type.lineHeight.body')};

  ${media.greaterThan('s')`
    font-size: 1.25rem;
  `}

  ${media.greaterThan('m')`
    font-size: 1.375rem;

    &:only-child {
      margin-bottom: ${spacing(5)};
    }
  `}
`

export const H1 = styled.h1`
  ${Heading};
  ${H1Sizes};
`
export const H2 = styled.h2`
  ${Heading};
  ${H2Sizes};
`

export const H3 = styled.h3`
  ${Heading};
  ${H3Sizes};
`

export const H4 = styled.h4`
  ${Heading};
  ${H4Sizes};
`

export const H5 = styled.h5`
  ${Heading};
  ${H5Sizes};
`

export const H6 = styled.h6`
  ${Heading};
  ${H6Sizes};
`

export const PIntro = styled.p`
  ${LargeTypeSizes}
`

export const Label = styled.span`
  ${ITCMedium};
  font-size: 0.75rem;
  letter-spacing: 0.25em;
  text-transform: uppercase;

  ${media.greaterThan('s')`
    font-size: 0.875rem;
  `}

  ${media.greaterThan('l')`
    font-size: 1rem;
  `}
`

export const MetaStyles = css`
  ${ITCBold};
  font-size: 0.75rem;
  letter-spacing: ${tokens.get('type.letterSpacing.medium')};
  text-transform: uppercase;
  margin-bottom: ${spacing()};

  ${media.greaterThan('s')`
    font-size: 0.875rem;
  `}

  ${media.greaterThan('l')`
    font-size: 1rem;
  `}
`

export const Meta = styled.div`
  ${MetaStyles}
`

export const HomeHeroHeading = styled.h1`
  ${Heading};
  line-height: ${tokens.get('type.lineHeight.homeHeading')};
  font-size: 2.5rem;

  ${media.greaterThan('s')`
    font-size: 3rem;
  `}

  ${media.greaterThan('m')`
    font-size: 4rem;
  `}

  ${media.greaterThan('xl')`
    font-size: 5rem;
  `}
`

export const HomeHeroSubHeading = styled.h2`
  ${Heading};
  line-height: ${tokens.get('type.lineHeight.homeSubHeading')};
  font-size: 1.5rem;

  ${media.greaterThan('s')`
    font-size: 2rem;
  `}

  ${media.greaterThan('m')`
    font-size: 3rem;
  `}

  ${media.greaterThan('xl')`
    font-size: 3.5rem;
  `}
`
